var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {}, [_c('b-card', [_c('h3', {
    staticClass: "text-center"
  }, [_vm._v("Nilai Raport Semester 1-5")]), _c('br'), _c('b-table-simple', {
    attrs: {
      "responsive": ""
    }
  }, [_c('b-thead', [_c('b-tr', [_c('b-th', [_vm._v("Mapel")]), _c('b-th', [_vm._v("Semester 1")]), _c('b-th', [_vm._v("Semester 2")]), _c('b-th', [_vm._v("Semester 3")]), _c('b-th', [_vm._v("Semester 4")]), _c('b-th', [_vm._v("Semester 5")])], 1)], 1), _c('b-tbody', _vm._l(_vm.data.nilai_semester, function (item, i) {
    return _c('b-tr', {
      key: i
    }, [_c('b-td', [_vm._v(" " + _vm._s(item.nama_mapel) + " ")]), _vm._l(item.nilai, function (data, index) {
      return _c('b-td', {
        key: index
      }, [_vm._v(_vm._s(Math.floor(data.nilai)))]);
    })], 2);
  }), 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }