<template>
  <div class="">
    <b-card>
      <b-row>
        <b-col md="8">
          <label for="">Nama</label>
          <h4>{{ snbpDetail.name }}</h4>
          <label for="">Asal Sekolah</label>
          <h4>{{ snbpDetail.school_origin_info ? snbpDetail.school_origin_info.nama_sekolah : snbpDetail.school_origin }}</h4>
        </b-col>
        <b-col md="4" v-if="dataMemberBeli != null">
          <h5>{{ snbpDetail.name }} Sudah men-submit sebanyak</h5>
          <h3>{{ dataMemberBeli.submit_count }}x</h3>
        </b-col>
      </b-row>
    </b-card>
    <b-tabs content-class="pt-1">
      <b-tab title="Raport"> <Raport :data="snbpDetail" /> </b-tab>
      <b-tab title="Piagam"> <Piagam :data="snbpDetail" /> </b-tab>
      <b-tab title="Hasil"> <Hasil :data="snbpDetail" /> </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import { BCard, BTabs, BTab, BRow, BCol } from "bootstrap-vue";
import Raport from "./components/Raport";
import Piagam from "./components/Piagam";
import Hasil from "./components/Hasil";
export default {
  components: {
    BCard,
    BTabs,
    BTab,
    BRow,
    BCol,
    Raport,
    Piagam,
    Hasil,
  },
  data() {
    return {
      dataMemberBeli: null,
    };
  },
  computed: {
    snbpDetail() {
      return this.$store.state.snbp.detail;
    },
  },
  methods: {
    cekMemberBeli() {
      let user_id = this.snbpDetail.user_id;
      this.$store
        .dispatch("snbp/memberCek", user_id)
        .then((res) => {
          this.dataMemberBeli = res.data;
        })
        .catch((err) => {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Info!`,
              icon: "AlertIcon",
              variant: "warning",
              text: err.response.data.message,
            },
          });
        });
    },
  },
  created() {
    this.cekMemberBeli();
  },
};
</script>

<style></style>
