var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {}, [_vm.data.file_piagam[0] != null ? _c('b-card', [_c('table', {
    staticClass: "table reponsive"
  }, [_c('tr', [_c('th', [_vm._v("No")]), _c('th', [_vm._v("Nama")]), _c('th', [_vm._v("File")])]), _vm._l(_vm.data.file_piagam, function (item, i) {
    return _c('tr', {
      key: i
    }, [_c('td', [_vm._v(_vm._s(i + 1))]), _c('td', [_vm._v(_vm._s(item.name))]), _c('td', [_c('a', {
      staticClass: "btn btn-primary",
      attrs: {
        "href": _vm.apiFile + item.file,
        "target": "_blank"
      }
    }, [_vm._v("Lihat")])])]);
  })], 2)]) : _c('b-card', [_c('center', [_vm._v("Anda Tidak Mengisi Piagam")])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }